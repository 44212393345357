import { getCoords, setCount } from "../utils";

export const setCounter = () => {
  let counter = document.querySelectorAll(".counter_block"),
    counterBlock = document.querySelector(".counter"),
    pass = true;

  if (counter.length == 0) {
    return;
  }

  Array.prototype.forEach.call(counter, function (item) {
    let total = item.getAttribute("data-count"),
      countElem = item.querySelector(".count"),
      positions = total.split("");
    countElem.textContent = "";
    for (var i = 0; i < positions.length; i++) {
      let newElem = document.createElement("span");
      countElem.append(newElem);
      newElem.textContent = "0";
      newElem.setAttribute("data-number", positions[i]);
      newElem.setAttribute("class", "count_number");
    }
  });

  window.addEventListener("scroll", function () {
    let numbers = document.querySelectorAll(".count_number"),
      scrolled = window.pageYOffset + window.innerHeight,
      elemCoords = getCoords(counterBlock).top;

    if (pass == false) {
      return;
    }

    if (scrolled >= elemCoords + 100) {
      Array.prototype.forEach.call(numbers, function (item) {
        pass = false;
        let number = item.getAttribute("data-number");
        setCount(item, number);
      });
    }
  });
};
