import { tns } from "tiny-slider";

export const reviewsSlider = () => {
  if (document.querySelector(".reviews_slider")) {
    tns({
      container: ".reviews_slider__items",
      items: 1,
      gutter: 20,
      controlsContainer: ".reviews_slider .slider_controls",
      autoplay: false,
      mouseDrag: false,
      nav: false,
      loop: false,
      animateDelay: 6000,
      controls: true,
      controlsPosition: "bottom",
      controlsText: ["", ""],
      responsive: {
        1025: {
          items: 2,
        },
      },
    });
  }
};
