import { tns } from "tiny-slider";

export const mainBlockSlider = () => {
  if (document.querySelector(".main_block__slider")) {
    tns({
      container: ".main_block__items",
      items: 1,
      autoplay: true,
      mouseDrag: true,
      nav: true,
      navContainer: ".main_block__slider .slider_navs",
      animateDelay: 4000,
      controls: false,
      autoplay: true,
    });
  }
};
