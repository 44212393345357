import { cartSuccesPopup } from "./../CartSuccesPopup";

export const addToCardCallback = () => {
  let cartSuccessTimeOut;

  window.miniShop2.Callbacks.add(
    "Cart.add.response.success",
    "addCartResponse",
    () => {
      clearInterval(cartSuccessTimeOut);

      cartSuccesPopup(true);

      cartSuccessTimeOut = setTimeout(() => {
        cartSuccesPopup(false);
      }, 2000);
    }
  );
};
