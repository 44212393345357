import "@dev/scss/main.scss";
import "./modules/Select";
import "@plugins/polyfill.js";
import MiniShop2 from "@modules/MiniShop2/MiniShop2";
import { delegate, popup, toggleHeader, sendForm } from "./utils";
import { addToCardCallback } from "./modules/MiniShop2/Callbacks";
import MmenuLight from "mmenu-light";

function importAll(r) {
  return r.keys().map(r);
}

importAll(require.context("../sprite/", true, /\.svg$/));

const { miniShop2Config } = window;

if (miniShop2Config) {
  const miniShop2 = new MiniShop2(miniShop2Config);
  miniShop2.initialize();
  window.miniShop2 = miniShop2;

  addToCardCallback();
}

const header = document.querySelector(".header_main"),
  closePopupBtn = document.querySelectorAll(".popup_сlose"),
  forms = document.querySelectorAll(".form"),
  burger = document.querySelector(".hamburger"),
  inputs = document.querySelectorAll(".form_input");

if (header) {
  toggleHeader(header);

  window.addEventListener("scroll", function () {
    toggleHeader(header);
  });
}

Array.prototype.forEach.call(inputs, function (item) {
  let input = item.querySelector("input, textarea, select");

  item.addEventListener("mouseover", function () {
    this.classList.add("selected");
  });

  item.addEventListener("change", function () {
    if (!input.value) {
      this.classList.remove("selected");
    } else {
      this.classList.add("selected");
    }
  });

  item.addEventListener("mouseleave", function () {
    if (!input.value) {
      this.classList.remove("selected");
    }
  });
});

Array.prototype.forEach.call(closePopupBtn, function (btn) {
  btn.addEventListener("click", function () {
      
    console.log(this.parentNode.parentNode);
    
    if(this.parentNode.parentNode.classList.contains('overlay')) {
        let overlay = this.parentNode.parentNode;
        overlay.classList.add("disabled");
        setTimeout(function () {
          overlay.classList.remove("active");
          overlay.classList.remove("disabled");
        }, 500);
    }
    

  });
});

Array.prototype.forEach.call(forms, function (form) {
  form.addEventListener("submit", function (e) {
    e.preventDefault();
    sendForm(form);
  });
});

delegate(document, "click", ".button_callback", function () {
  const popupMain = document.querySelector(".callback_popup");
  popup(popupMain);
});

if (window.screen.width <= 1024) {
  const menu = new MmenuLight(document.querySelector(".header_nav"));

  menu.navigation({
    title: "Меню",
  });

  const drawer = menu.offcanvas({
    title: "Меню",
  });

  burger.addEventListener("click", (evnt) => {
    evnt.preventDefault();
    drawer.open();
  });
}

if (window.screen.width <= 480) {
  document
    .querySelector(".mm-ocd__content")
    .append(document.querySelector(".header_contacts"))
}
  
// правка от 18,08,2022 (Олег)
    // === аккордеон
    // if ($('.accord')) {
    // 	$('.accord__tab').on('click', function () {
    // 		if ($('.accord__tab').hasClass('opened')) {
    // 			$(this).next('.accord__tab-content').slideToggle();
    // 			$(this).toggleClass('opened');
    // 			$('.accord__tab').not(this).removeClass('opened');
    // 			$('.accord__tab').not(this).next('.accord__tab-content').slideUp();
    // 		} else {
    // 			$(this).next('.accord__tab-content').slideToggle();
    // 			$(this).addClass('opened');
    // 		}
    // 	});
    // }
    if ($('.accord')) {
    	$('.ss-selected-option').on('click', function (e) {
    		if ($(this).hasClass('opened')) {
    			$(this).next('.ss-dropdown').slideUp();
    			$(this).removeClass('opened');
    			$(this).closest('.style-select').removeClass('open');
    			$('.style-select__mob-btn').addClass('open');
    			$('.ss-selected-option').not(this).removeClass('opened');
    			$('.ss-selected-option').not(this).next('.ss-dropdown').slideUp();
    		} else {
    			$('.style-select').removeClass('open');
    			$(this).addClass('opened');
    			$(this).next('.ss-dropdown').slideDown();
    			$(this).closest('.style-select').addClass('open');
    			$('.style-select__mob-btn').addClass('open');
    			$('.ss-selected-option').not(this).next('.ss-dropdown').slideUp();
    			$('.ss-selected-option').not(this).removeClass('opened');
    		}
    	});
    }
    
    if ($('.style-select__mob-btn')) {
    	$('.style-select__mob-btn').on('click', function () {
    		if (!$(this).hasClass('opened')) {
    			$(this).next('.new-filters').slideDown();
    			$(this).addClass('opened');
    		} else {
    			$(this).next('.new-filters').slideUp();
    			$(this).removeClass('opened');
    			$(this).removeClass('open');
    		}
    	});
    }
    
    
    let filterInputs = document.querySelectorAll('.style-select input');
    // let filterActiveInputs = document.querySelectorAll('.style-select input:checked');
    // let styleSelect = document.querySelectorAll('.style-select');
    
        for (let item of filterInputs) {
            let ssSelectedOption = item.parentElement.parentElement.previousElementSibling;
            if (item.checked) {
                ssSelectedOption.classList.add('checked');
                item.classList.add('checked');
            }
        }
        document.addEventListener('click', function() {
            for (let item of filterInputs) {
                let ssSelectedOption = item.parentElement.parentElement.previousElementSibling;
                if (item.checked) {
                    ssSelectedOption.classList.add('checked');
                    item.classList.add('checked');
                } else if (item.classList.contains('checked')) {
                    ssSelectedOption.classList.remove('checked');
                    item.classList.remove('checked');
                }
                
            }
        }); 

	// === Скрыть dropdown, по клику вне блока
	let btnCategories = $(".ss-selected-option"); // указываем кнопку
	let categories = $(".ss-dropdown"); // указываем список
	
	$(document).mouseup(function (e) { // событие клика по веб-документу
    	if ( ! btnCategories.is(e.target) && btnCategories.has(e.target).length === 0 //&&
    		 // если клик был не по нашему блоку
    		 //! categories.is(e.target) && categories.has(e.target).length === 0
    		 // и не по его дочерним элементам
        ) {
    	  categories.slideUp(); // скрываем его
    		$('.style-select').removeClass('open');
    		$('.ss-selected-option').removeClass('opened');
    		$('.style-select__mob-btn').removeClass('opened');
        	if (window.innerWidth < 600) {
    	    	$('.style-select__mob-btn').next('.new-filters').slideUp();
            } 
    	}
    });
	// === END
// ====================





