import { delegate } from "../utils";

export const reviewsCuttedClick = () => {
  delegate(document, "click", ".cutted_button", function () {
    const props = {
      stateAttr: "data-state",
      activeClass: "--active",
      mainClass: "cutted_text",
    };

    const parentWrapper = this.closest(".cutted");
    const visibleText = parentWrapper.querySelector(
      `.${props.mainClass}--first`
    );
    const hiddenText = parentWrapper.querySelector(
      `.${props.mainClass}--second`
    );
    const state = this.getAttribute(props.stateAttr);

    if (state === "disabled") {
      hiddenText.classList.add(`${props.mainClass}${props.activeClass}`);
      visibleText.classList.add(`${props.mainClass}${props.activeClass}`);
      this.setAttribute(props.stateAttr, "enabled");
      return;
    }

    hiddenText.classList.remove(`${props.mainClass}${props.activeClass}`);
    visibleText.classList.remove(`${props.mainClass}${props.activeClass}`);
    this.setAttribute(props.stateAttr, "disabled");
  });
};
