import {
  setShow,
  setHidden,
  setEnabled,
  setDisabled,
  isHidden,
  formatValue,
} from "@modules/MiniShop2/Utils";

export default class Order {
  constructor(miniShop2) {
    this.order = "#msOrder";
    this.deliveries = "#deliveries";
    this.payments = "#payments";
    this.deliveryInput = 'select[name="delivery"]';
    this.inputParent = ".input-parent";
    this.inputParent = ".form__field";
    this.paymentInput = 'select[name="payment"]';
    this.paymentInputUniquePrefix = "option#payment_";
    this.deliveryInputUniquePrefix = "option#delivery_";
    this.deliveryBlockSelector = "[data-delivery]";
    this.orderCost = "#ms2_order_cost";
    this.inputErrorClass = "form_item__input--error";
    this.doc = document.querySelector(this.order);
    this.miniShop2 = miniShop2;
    this.callbacks = {
      add: this.miniShop2.config.callbacksObjectTemplate(),
      getcost: this.miniShop2.config.callbacksObjectTemplate(),
      clean: this.miniShop2.config.callbacksObjectTemplate(),
      submit: this.miniShop2.config.callbacksObjectTemplate(),
      getrequired: this.miniShop2.config.callbacksObjectTemplate(),
    };
  }

  initialize() {
    if (this.doc) {
      const orderClears = this.doc.querySelectorAll(
        `[name="${this.miniShop2.actionName}"][value="order/clean"]`
      );
      if (orderClears) {
        orderClears.forEach((orderClear) => {
          orderClear.addEventListener("click", (e) => {
            this.clean();
            e.preventDefault();
          });
        });
      }
      const fields = this.doc.querySelectorAll("input, textarea, select");
      if (fields) {
        fields.forEach((field) => {
          field.addEventListener("change", () => {
            const key = field.getAttribute("name");
            const { value } = field;
            this.add(key, value);
          });
        });
      }

      const deliveryInputs = this.doc.querySelector(`${this.deliveryInput}`);

      if (!deliveryInputs) {
        return;
      }

      const deliveryInputChecked = Array.from(deliveryInputs).find(
        (deliveryInput) => deliveryInputs.value === deliveryInput.value
      );

      if (deliveryInputChecked) {
        deliveryInputs.dispatchEvent(new Event("change"));
        deliveryInputs.dispatchEvent(new Event("customChange"));
      }

      this.checkDeliverTextAddres();
    }
  }

  checkDeliverTextAddres() {
    const deliveryInputs = this.doc.querySelector(`${this.deliveryInput}`);
    const addressField = document.querySelector("#text_address");

    if (
      !addressField
        .getAttribute("passed-delivery")
        .includes(deliveryInputs.value)
    ) {
      addressField.classList.add("form_item__input--disabled");
      addressField.disabled = true;
      return;
    }

    addressField.classList.remove("form_item__input--disabled");
    addressField.disabled = false;
  }

  add(key, value) {
    const { callbacks } = this;
    const oldValue = value;
    callbacks.add.response.success = (response) => {
      (() => {
        let field = this.doc.querySelector(`[name="${key}"]`);
        switch (key) {
          case "delivery":
            field = this.doc.querySelector(
              this.deliveryInputUniquePrefix + response.data[key]
            );
            if (response.data[key] !== oldValue) {
              field.dispatchEvent(new Event("change"));
            } else {
              this.getrequired(value);
              this.updatePayments(
                field.dataset.payments || field.getAttribute("data-payments")
              );
              this.updateDeliveryFields(value);
              this.getCost();
              this.checkDeliverTextAddres();
            }
            break;
          case "payment":
            field = this.doc.querySelector(this.paymentInput);
            if (response.data[key] !== oldValue) {
              field.dispatchEvent(new Event("change"));
            } else {
              this.getCost();
            }
            break;
          default:
        }
        let newValue = response.data[key];
        if (newValue === "" && field.getAttribute("readonly") !== null) {
          if (Number.isFinite(Number(oldValue))) {
            newValue = 0;
          }
        }
        if (window.maskObjects[field.getAttribute("id")]) {
          window.maskObjects[field.getAttribute("id")].update();
        }
        // eslint-disable-next-line max-len
        // TODO: если 2 поля с одинаковым неймом (например, радио) делает у обоих радио одно значение
        field.value = newValue;

        this.removeErrorClass(field);
      })(key, value, oldValue);
    };
    callbacks.add.response.error = () => {
      (() => {
        const field = this.doc.querySelector(`[name="${key}"]`);
        this.setErrorClass(field);
      })(key);
    };

    const data = {
      key,
      value,
    };
    data[this.miniShop2.actionName] = "order/add";
    this.miniShop2.send(
      data,
      this.callbacks.add,
      this.miniShop2.Callbacks.Order.add
    );
  }

  updatePayments(payments) {
    const paymentsArray = JSON.parse(payments);
    let paymentInputs = this.doc.querySelectorAll(`${this.paymentInput}`);
    let paymentSelect = this.doc.querySelector(`${this.paymentInput}`);

    if (paymentInputs[0].localName === "select") {
      paymentInputs = this.doc.querySelectorAll(`${this.paymentInput} option`);
    }

    paymentInputs.forEach((paymentInput) => {
      setDisabled(paymentInput);

      if (paymentSelect.localName === "select") {
        paymentSelect.dispatchEvent(new Event("changeAttribute"));
      }

      if (payments.length > 0) {
        const filteredPaymentInput = paymentsArray.filter(
          (payment) =>
            `${this.paymentInputUniquePrefix}${payment}` ===
            `${paymentInput.tagName.toLowerCase()}#${paymentInput.id}`
        );
        if (filteredPaymentInput.length) {
          const filteredPaymentInputId = filteredPaymentInput[0];
          const element = this.doc.querySelector(
            `${this.paymentInputUniquePrefix}${filteredPaymentInputId}`
          );
          setEnabled(element);
          if (paymentSelect.localName === "select") {
            paymentSelect.dispatchEvent(new Event("changeAttribute"));
          }
        }
      }
    });

    if (paymentSelect.localName === "select") {
      const paymentOptions = paymentSelect.querySelectorAll(`option`);

      Array.prototype.some.call(paymentOptions, (option) => {
        if (!option.disabled) {
          paymentSelect.value = option.value;
          paymentSelect.dispatchEvent(new Event("change"));
          paymentSelect.dispatchEvent(new Event("customChange"));
          return true;
        }
      });
    }

    const checkedVisiblePaymentInputs = Array.from(paymentInputs).filter(
      (paymentInput) => !isHidden(paymentInput) && paymentInput.checked
    );
    if (checkedVisiblePaymentInputs.length === 0) {
      let checkedVisiblePaymentInput;
      paymentInputs.forEach((paymentInput) => {
        if (
          !isHidden(paymentInput) &&
          typeof checkedVisiblePaymentInput === "undefined"
        ) {
          checkedVisiblePaymentInput = paymentInput;
        }
      });
      if (checkedVisiblePaymentInput) {
        checkedVisiblePaymentInput.checked = true;
        checkedVisiblePaymentInput.dispatchEvent(new Event("change"));
      }
    }
  }

  getCost() {
    const { callbacks } = this;
    callbacks.getcost.response.success = (response) => {
      this.doc.querySelectorAll(this.orderCost).forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element.textContent = formatValue(
          response.data.cost,
          this.miniShop2.config.price_format,
          this.miniShop2.config.price_format_no_zeros
        );
      });
    };
    const data = {};
    data[this.miniShop2.actionName] = "order/getcost";
    this.miniShop2.send(
      data,
      this.callbacks.getcost,
      this.miniShop2.Callbacks.Order.getcost
    );
  }

  clean() {
    const { callbacks } = this;
    callbacks.clean.response.success = () => {
      window.location.reload();
    };

    const data = {};
    data[this.miniShop2.actionName] = "order/clean";
    this.miniShop2.send(
      data,
      this.callbacks.clean,
      this.miniShop2.Callbacks.Order.clean
    );
  }

  submit() {
    if (this.miniShop2.ajaxProgress) {
      this.submit();
    }

    const { callbacks } = this;
    this.callbacks.submit.before = () => {
      this.doc.querySelectorAll("button, a").forEach((element) => {
        setDisabled(element);
      });
    };
    callbacks.submit.response.success = (response) => {
      ym(88568355, 'reachGoal', 'order_send');
      gtag('event', 'submit', {'event_category': 'form', 'event_label': 'order_send'});
      if (response.data.redirect) {
        document.location.href = response.data.redirect;
      } else if (response.data.msorder) {
        document.location.href = `${
          document.location.origin +
          document.location.pathname +
          (document.location.search
            ? `${document.location.search}&`
            : `?msorder=${response.data.msorder}`)
        }`;
      } else {
        document.location.reload();
      }
    };
    callbacks.submit.response.error = (response) => {
      setTimeout(() => {
        this.doc.querySelectorAll("button, a").forEach((element) => {
          setEnabled(element);
        });
      }, 3 * this.miniShop2.timeout);
      this.doc.querySelectorAll("[name]").forEach((element) => {
        this.removeErrorClass(element);
      });
      response.data.forEach((key) => {
        const field = this.doc.querySelector(`[name="${key}"]`);
        this.setErrorClass(field);
      });
    };
    return this.miniShop2.send(
      this.miniShop2.sendData.formData,
      this.callbacks.submit,
      this.miniShop2.Callbacks.Order.submit
    );
  }

  getrequired(value) {
    const { callbacks } = this;
    callbacks.getrequired.response.success = (response) => {
      const { requires } = response.data;
      if (requires) {
        requires.forEach((require) => {
          const element = this.doc.querySelector(`[name=${require}]`);
          element.classList.add("required");
          const elementParent = element.closest(this.inputParent);
          if (elementParent) {
            elementParent.classList.add("required");
          }
        });
      }
    };
    callbacks.getrequired.response.error = () => {
      const fields = this.doc.querySelectorAll("[name]");
      if (fields.length) {
        fields.forEach((field) => {
          field.classList.remove("required");
          const fieldParent = field.closest(this.inputParent);
          if (fieldParent) {
            fieldParent.classList.remove("required");
          }
        });
      }
    };

    const data = {
      id: value,
    };
    data[this.miniShop2.actionName] = "order/getrequired";
    this.miniShop2.send(
      data,
      this.callbacks.getrequired,
      this.miniShop2.Callbacks.Order.getrequired
    );
  }

  updateDeliveryFields(delivery) {
    const deliveryBlocks = document.querySelectorAll(
      this.deliveryBlockSelector
    );
    if (deliveryBlocks.length) {
      deliveryBlocks.forEach((deliveryBlock) => {
        if (deliveryBlock.dataset.delivery === delivery) {
          setShow(deliveryBlock);
        } else {
          setHidden(deliveryBlock);
        }
      });
    }
  }

  setErrorClass(field) {
    if (field) {
      if (
        field.getAttribute("type") === "checkbox" ||
        field.getAttribute("type") === "radio"
      ) {
        const parent = field.closest(this.inputParent);
        if (parent) {
          parent.classList.add(this.inputErrorClass);
        }
      } else {
        field.classList.add(this.inputErrorClass);
      }
    }
  }

  removeErrorClass(field) {
    if (field) {
      field.classList.remove(this.inputErrorClass);
      const parent = field.closest(this.inputParent);
      if (parent) {
        parent.classList.remove(this.inputErrorClass);
      }
    }
  }
}
