import { tns } from "tiny-slider";
import { addToCardCallback } from "./MiniShop2/Callbacks";

export const productSlider = () => {
  if (document.querySelector(".product_slider__container")) {
    tns({
      container: ".product_slider__container",
      items: 1,
      gutter: 10,
      controlsContainer: ".product_slider .slider_controls",
      autoplay: true,
      mouseDrag: false,
      nav: false,
      animateDelay: 6000,
      controls: true,
      navPosition: "bottom",
      controlsText: ["", ""],
      responsive: {
        380: {
          items: 2,
          gutter: 10,
        },
        768: {
          items: 3,
        },
        1024: {
          items: 4,
          gutter: 15,
        },
        1280: {
          items: 4,
        },
        1440: {
          items: 5,
        },
      },
      onInit: () => {
        window.miniShop2.initialize();
        addToCardCallback();
      },
    });
  }
};
