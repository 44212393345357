import "../plugins/styleselect";

var styleSelect = require("../plugins/styleselect");

window.styleSelect = styleSelect;

const selects = document.querySelectorAll("select");

if (selects) {
  selects.forEach((select) => {
    styleSelect(select);
  });
}

// import Choices from "choices.js";

// window.choiceObjects = {};

// if (document.querySelector(".select")) {
//   const selectList = document.querySelectorAll(".select");
//   if (selectList.length) {
//     selectList.forEach((select) => {
//       const theme = select.getAttribute("data-theme");
//       // eslint-disable-next-line no-new
//       const choice = new Choices(select, {
//         searchEnabled: false,
//         searchChoices: false,
//         itemSelectText: "",
//         removeItems: false,
//         classNames: {
//           containerOuter: `choices choices--${theme}`,
//         },
//       });
//       if (choice.initialised) {
//         window.choiceObjects[select.getAttribute("name")] = choice;
//       }
//     });
//   }
// }

// console.log(window.choiceObjects);
