import { csrfToken } from "./constants";

export const fetchJSON = (url, config) => {
  const { headers, ...otherConfig } = config;
  const modifierConfig = {
    cache: "no-cache",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": csrfToken,
      ...headers,
    },
    ...otherConfig,
  };

  return fetch(url, modifierConfig).then((response) => response.json());
};

export const delegate = (el, evt, sel, handler) => {
  el.addEventListener(evt, function (event) {
    let t = event.target;
    while (t && t !== this) {
      if (t.matches(sel)) {
        handler.call(t, event);
      }
      t = t.parentNode;
    }
  });
};

export const scrollTo = (el) => {
  const headerHeight = document.querySelector(".header").clientHeight;
  const block = document.querySelector(el.getAttribute("href"));
  const top =
    block.getBoundingClientRect().top + window.pageYOffset - headerHeight;

  window.scrollTo({
    top,
    behavior: "smooth",
  });
};

const validateSize = (fileInput, size) => {
  let fileObj;

  if (typeof window.ActiveXObject === "function") {
    fileObj = new window.ActiveXObject("Scripting.FileSystemObject").getFile(
      fileInput.value
    );
  } else {
    [fileObj] = fileInput.files;
  }

  const oSize = fileObj.size;

  if (oSize > size * 1024 * 1024) {
    return false;
  }

  return true;
};

export const checkFile = (input) => {
  const fileInput = input;
  const types = ["docx", "doc", "pdf"];
  const parent = fileInput.closest(".form_input");
  const fileType = fileInput.value.split(".").pop().toLowerCase();
  const fileName = fileInput.value
    .split(/(\\|\/)/g)
    .pop()
    .toLowerCase();
  const warningClass = "form_input_file_warning";
  const warning = parent.querySelector(`.${warningClass}`);

  let message = "";
  let addClass = "color--red";
  let removeClass = "color--green";

  if (types.indexOf(fileType) === -1) {
    message = "Недопустимый формат файла";
    fileInput.value = "";
  } else if (!validateSize(input, 10)) {
    message = "Файл не должен привышать 10 мбайт";
  } else {
    message = fileName;
    addClass = "color--green";
    removeClass = "color--red";
  }

  warning.textContent = message;
  warning.classList.remove(removeClass);
  warning.classList.add(`${warningClass}--active`, addClass);
};

export const isElementInViewport = (el) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= -300 &&
    rect.bottom <=
      (window.outerHeight || document.documentElement.outerHeight) + 300
  );
};

export const addAutoResize = (input) => {
  const element = input;
  element.style.boxSizing = "border-box";
  const offset = element.offsetHeight - element.clientHeight;

  element.addEventListener("input", (e) => {
    const { target } = e;
    target.style.height = "auto";
    target.style.height = `${target.scrollHeight + offset}px`;
  });

  element.removeAttribute("data-autoresize");
};

export function ieVersion() {
  let ua = window.navigator.userAgent;
  if (ua.indexOf("Trident/7.0") > -1) return 11;
  else if (ua.indexOf("Trident/6.0") > -1) return 10;
  else if (ua.indexOf("Trident/5.0") > -1) return 9;
  else return 0; // not IE9, 10 or 11
}

export function getCoords(elem) {
  let box = elem.getBoundingClientRect();
  return {
    top: box.top + pageYOffset,
    left: box.left + pageXOffset,
  };
}

export function toggleHeader(header) {
  if (window.pageYOffset > 200) {
    header.classList.add("scrolled");
  } else {
    header.classList.remove("scrolled");
  }
}

export function setCount(elem, num, currentNum) {
  currentNum ? "" : (currentNum = 0);

  setTimeout(function () {
    if (currentNum < num) {
      currentNum += 1;
      elem.textContent = currentNum;
      return setCount(elem, num, currentNum);
    }
  }, 200);
}

export function popup(item) {
  let overlay = item.closest(".overlay");
  overlay.classList.contains("active") ? "" : overlay.classList.add("active");
  return;
}

export function sendForm(form) {
  var url = form.getAttribute("action"),
    csrf = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content"),
    formValues = new FormData(form),
    button = form.querySelector('button[type="submit"]'),
    buttonText = button.textContent,
    values = form.values;
  button.classList.add("sending");
  button.textContent = "Отправка...";
  formValues.append("csrf_token", csrf);

  var formName = form.elements.formName.value;
  send(formName);

  function send(formName) {
    fetch(url, {
      method: "POST",
      cache: "no-cache",
      headers: { "X-Requested-With": "XMLHttpRequest", "X-CSRF-TOKEN": csrf },
      body: formValues,
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        if (data["success"] == "error") {
          let errors = JSON.parse(data["errors"]);

          errors = Object.keys(errors);
          errors.forEach(function (item) {
            form
              .querySelector('input[name="' + item + '"]')
              .parentNode.classList.add("error");
          });
        } else {
          console.log(formName);
          switch (formName) {
              case 'Форма обратной связи попап':
                ym(88568355, 'reachGoal', 'call_send'); gtag('event', 'submit', {'event_category': 'form', 'event_label': 'call_send'});
                break;
              case 'Форма обратной расчет':
                ym(88568355, 'reachGoal', 'rasschet_send'); gtag('event', 'submit', {'event_category': 'form', 'event_label': 'rasschet_send'});
                break;
              case 'Форма остались вопросы':
                ym(88568355, 'reachGoal', 'question_send'); gtag('event', 'submit', {'event_category': 'form', 'event_label': 'question_send'});
                break;
              case 'Купить в 1 клик':
                ym(88568355, 'reachGoal', 'order1_send'); gtag('event', 'submit', {'event_category': 'form', 'event_label': 'order1_send'});
                break;
          }  
              
          let inputs = form.querySelectorAll("input");

          popup(document.querySelector(".thanks"));

          if(form.closest(".overlay")){
            form.closest(".overlay").classList.remove("active");
          }

          form.reset();

          Array.prototype.forEach.call(inputs, function (item) {
            item.classList.remove("error");
          });
          
        }

        button.classList.remove("sending");
        button.textContent = buttonText;
      });
  }
}

export const fileValid = () => {
  let inputs = document.querySelectorAll('input[type="file"]');
  Array.prototype.forEach.call(inputs, (item) => {
    if (!item.closest(".ec-file_type")) {
      return;
    }

    item.addEventListener("change", function () {
      let file_type = item.value.split(".").pop().toLowerCase(),
        file_name = item.value
          .split(/(\\|\/)/g)
          .pop()
          .toLowerCase(),
        types = ["jpg", "jpeg", "png"],
        form = document.querySelector(".ec-form"),
        submitButton = form.querySelector('button[type="submit"]'),
        sourceText = "Не более 20мб: jpeg, png, docx, xls, pdf",
        parent = item.parentNode,
        warning = parent.querySelector(".form_item__fileLabel--warning"),
        placeholder = parent.querySelector(".form_item__fileLabel--val");

      if (types.indexOf(file_type) == -1) {
        warning.innerHTML = "Недопустимый формат файла";
        item.value = "";
        placeholder.innerText = "";
        warning.classList.add("form_item__fileLabel--warning-visible");
        submitButton.setAttribute("disabled", "disabled");
      } else if (!validateSize(item, 0.5)) {
        parent.querySelector(".form_item__fileLabel--warning").innerHTML =
          "Файл не должен привышать 500 кбайт";
        placeholder.innerText = "";
        warning.classList.add("form_item__fileLabel--warning-visible");
        submitButton.setAttribute("disabled", "disabled");
      } else {
        warning.classList.remove("form_item__fileLabel--warning-visible");
        placeholder.innerText = file_name;
        submitButton.removeAttribute("disabled");
      }
    });
  });
};

export function classToggle(item, classes) {
  if (item.classList.contains(classes)) {
    item.classList.remove(classes);
  } else {
    item.classList.add(classes);
  }
}
