export const cartSuccesPopup = (value) => {
  const popup = document.querySelector(".cart_success");

  if (value) {
    popup.classList.add("cart_success--active");
    return;
  }

  popup.classList.remove("cart_success--active");
};
