import "../main";
import "@cssTemplates/index.scss";

import { setCounter } from "../modules/SetCounter";
import { mainBlockSlider } from "../modules/MainBlockSlider";
import { productSlider } from "../modules/ProductSlider";
import { reviewsSlider } from "../modules/ReviewsSlider";
import { cartCounterCreaser } from "../modules/CartCounterCreaser";
import { reviewsCuttedClick } from "../modules/ReviewsCuttedClick";
import { counterSlider } from "../modules/CounterSlider";
import { delegate, popup } from "../utils";
import { tns } from "tiny-slider";
import WOW from "wow.js";

setCounter();
mainBlockSlider();
productSlider();
reviewsSlider();
cartCounterCreaser();
reviewsCuttedClick();
counterSlider();

delegate(document, "click", ".cost_call", function () {
  const popupMain = document.querySelector(".cost_popup");
  popup(popupMain);
});

delegate(document, "click", ".product_button__buy", function (e) {
    e.preventDefault();
  const popupMain = document.querySelector(".buy_popup");
  
  if (this.getAttribute("data-title")) {
      const popupTitle = popupMain.querySelector('.popup_info .sec').textContent = this.getAttribute("data-title");
      popupMain.querySelector('form input[name="product"]').value = this.getAttribute("data-title");
  }
  popup(popupMain);
});

const animations = new WOW({
  boxClass: "animate",
  animateClass: "animated",
  offset: 150,
  mobile: false,
  callback: function (box) {
    box.classList.add(box.getAttribute("data-animate"));

    if (box.getAttribute("data-delay")) {
      box.style.animationDelay = `${box.getAttribute("data-delay")}s`;
    }
  },
  resetAnimation: true,
});

if (window.screen.width <= 1024) {
  tns({
    container: ".news_items__slider",
    items: 1,
    gutter: 10,
    controlsContainer: ".news_items .slider_controls",
    autoplay: true,
    mouseDrag: true,
    nav: false,
    loop: false,
    animateDelay: 6000,
    controls: true,
    navPosition: "bottom",
    controlsText: ["", ""],
    responsive: {
      380: {
        items: 2,
      },
      1024: {
        items: 3,
        gutter: 15,
      },
    },
  });

  tns({
    container: ".stock_items__slider",
    items: 1,
    gutter: 10,
    controlsContainer: ".stock_items .slider_controls",
    autoplay: true,
    mouseDrag: true,
    nav: false,
    animateDelay: 6000,
    loop: false,
    controls: true,
    navPosition: "bottom",
    controlsText: ["", ""],
    responsive: {
      380: {
        items: 2,
      },
      1024: {
        items: 3,
        gutter: 15,
      },
    },
  });
} else {
  animations.init();
}
