import { tns } from "tiny-slider";

export const counterSlider = () => {
  tns({
    container: ".counter_slider",
    items: 1,
    nav: true,
    gutter: 10,
    autoplay: false,
    mouseDrag: true,
    animateDelay: 6000,
    loop: false,
    controls: false,
    navPosition: "bottom",
    controlsText: ["", ""],
    responsive: {
      481: {
        items: 2,
        gutter: 15,
      },
      1024: {
        items: 3,
      },
      1280: {
        items: 4,
        gutter: 15,
      }
    },
    onInit: (e) => {
      e.navContainer.classList.add("slider_navs");
      e.navItems.forEach((dot) => {
        dot.classList.add("slider_nav", "slider_nav--dark");
      });
    },
  });
};
