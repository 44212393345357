import { delegate } from "../utils";

export const cartCounterCreaser = () => {
  delegate(document, "click", ".counter_creaser", function (e) {
    const parentWrapper = this.parentNode;
    const input = parentWrapper.querySelector("input");

    if (this.getAttribute("data-input") === "next") {
      
      input.value = parseFloat(input.value) + 1;
      input.dispatchEvent(new Event("change"));
      return true;
    }
    
    if (parseFloat(input.value) === 1 && input.name === 'count') {
      return true;
    }
    
    if (parseFloat(input.value) <= 0) {
      return true;
    }

    input.value = parseFloat(input.value) - 1;
    input.dispatchEvent(new Event("change"));
    
  });
  
  
};
